<template>
  <v-sheet color="transparent">
    <Toolbar2 />
    <v-sheet>
      <div class="d-flex flex-column flex-md-row">
        <div class="w-full w-md-half">
          <v-img
            :src="require('@/assets/images/support.png')"
            :max-height="$vuetify.breakpoint.mdAndUp ? undefined : '300'"
            min-height="100%"
            alt="contact us"
            contain
          >
          </v-img>
        </div>
        <div class="w-full w-md-half d-flex align-center">
          <div class="pa-6 pa-md-12">
            <h2 class="text-h3 text-lg-h2">Contact Us</h2>
            <div class="text-h4 text-lg-h5 mt-5">We're here to help if you have questions or need assistance. Please fill out the form below and we will get back to you soon!</div>
            <div v-if="sent || error !== ''" class="pt-2">
              <v-alert v-if="error === ''" dense text type="success">
                Your request has been sent and we will get back to you shortly!
              </v-alert>
              <v-alert v-else dense text type="error">
                {{ error }}
              </v-alert>
            </div>
            <v-form v-if="!sent || error !== ''" ref="form" class="mt-2">
              <v-text-field
                v-model="name"
                label="Name"
                outlined
                solo
                :error-messages="errorMessages.name"
                :rules="[validators.required]"
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email"
                outlined
                solo
                :error-messages="errorMessages.email"
                :rules="[validators.required, validators.email]"
              ></v-text-field>
              <v-textarea
                v-model="message"
                label="Message"
                outlined
                solo
                :error-messages="errorMessages.email"
                :rules="[validators.required]"
                min-length="10"
              ></v-textarea>
              <v-btn
                v-if="!sending"
                x-large
                color="primary"
                min-width="180"
                @click="send"
              >Send</v-btn>
              <v-btn
                v-else
                x-large
                color="primary"
                min-width="180"
                disabled
              ><v-progress-circular indeterminate color="white"></v-progress-circular> Sending</v-btn>
            </v-form>
          </div>
        </div>
      </div>
    </v-sheet>
    <Footer4Demo />
  </v-sheet>
</template>

<script>
import Toolbar2 from '@/components/ui/landing/toolbar/Toolbar2.vue'
import Footer4Demo from '@/components/ui/landing/footer/Footer4Demo.vue'
import { required, email, minLength } from '@/validation'

export default {
  name: 'TermsOfService',
  components: {
    Toolbar2,
    Footer4Demo
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      sent: false,
      error: '',
      sending: false,
      errorMessages: {
        name: [],
        email: [],
        body: []
      },
      validators: {
        required,
        email,
        minLength
      }
    }
  },
  methods: {
    async send() {
      const isFormValid = this.$refs.form.validate()

      if (!isFormValid) return

      this.error = ''
      this.sending = true
      try {
        await this.$axios.post('/v1/user/contact', {
          name: this.name,
          email: this.email,
          message: this.message
        })
      } catch (error) {
        if (error.response.status === 429) {
          this.error = 'You have recently sent us a contact request. Please let us respond to that one first.'
        } else {
          this.error = 'There was an error sending your request. Please try again later.'
        }
        console.log('Error sending contact request:', error)
      } finally {
        this.sending = false
        this.sent = true
      }
    }
  }
}
</script>

<style></style>
