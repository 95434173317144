export const isEmpty = (value) => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

export const required = (value) => (value && value.length ? true : 'This field is required')

export const email = (value) => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every((val) => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export const minLength = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}
